<template>
	<v-container>
		<v-row v-if="postLoaded">
			<v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-card v-if="salesLoaded">
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Канал</th><th>Основные ссылки</th><th>Доп ссылки</th><th>Суммарно</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(sale, i) in sales" :key="i">
                      <td>{{getChannel(sale.channel_id)}}</td>
                      <td>{{sale.primary}}</td>
                      <td>{{sale.secondary}}</td>
                      <td>{{Number(sale.primary) + Number(sale.secondary)}}</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-right">{{totalSales()}}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row :align="'center'" class="mt-n3 pt-0">
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="post.shop"
                      :items="shops"
                      :item-text="'name'"
                      :item-value="'name'"
                      return-object
                      label="Магазин"
                      placeholder="Магазин"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="partnerId"
                      label="Партнёрка"
                      placeholder="Партнёрка"
                      outlined
                      :items="partners"
                      :item-text="'title'"
                      :item-value="'id'"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-text-field v-model="post.erid" label="ERID" outlined hide-details ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="post.aep_creative_id" label="ID креатива AEP" outlined hide-details ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <links v-if="partners.length>0" :links.sync="links" :partners="partners" :force-update="forceLinksUpdate" :errors="[]"></links>
            <v-btn color="error" class="mb-2 ml-3" @click="deletePost()">Удалить публикацию</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <editor ref="editor" :model-value.sync="post.text" :links="links" :text-length.sync="textLength"></editor>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                <v-radio-group v-model="method" :disabled="methodSelectDisabled">
                  <v-radio value="sendPhoto" label="Фото сверху"></v-radio>
                  <v-radio value="sendMessage" label="Фото снизу"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <v-text-field v-model="post.button_text" placeholder="Текст кнопки" label="Текст кнопки" outlined></v-text-field>
              </v-card-text>
            </v-card>

          </v-col>
        </v-row>

			</v-col>
			<v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="postChannels"
                          label="Канал"
                          placeholder="Канал"
                          outlined
                          multiple
                          :items="channels"
                          :item-text="'name'"
                          :item-value="'id'"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox v-model="disableAlert" label="Без уведомления"></v-checkbox>
                      </v-col>
                      <v-col v-if="allowTimePick" cols="12">
                        <v-btn color="blue" @click="now()">Сейчас</v-btn>
                      </v-col>
                      <v-col cols="12">
                        <div v-if="allowTimePick">Время публикации: <strong>{{showTime}}</strong></div>
                        <p v-if="allowTimePick"><v-btn color="primary" :disabled="hours === '' || minutes === '' || partnerId === null || errors.length>0" @click="publish2()">Запостить</v-btn></p>
                        <p v-else><v-btn color="primary" :disabled=" partnerId === null || errors.length>0" @click="publish2()">Обновить</v-btn></p>
                        <div v-if="errors.length>0">
                          Нельзя запостить:
                          <p v-for="(error, i) in errors" :key="i">{{error.desc}}</p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="allowTimePick" cols="4">
                    <v-btn-toggle v-model="hours" color="deep-purple accent-3" borderless>
                      <v-btn v-for="h in ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']"
                             :key="h"
                             :value="h"
                             text
                             :disabled="hourDisabled(h)"
                      >
                        {{h}}
                      </v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle v-model="minutes" class="ml-5" color="deep-purple accent-3" borderless>
                      <v-btn
                        v-for="m in ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']"
                        :key="m"
                        :value="m"
                        text
                        :disabled="minuteDisabled(m)"
                      >
                        {{m}}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-img
                  contain
                  v-if="this.post.product.image"
                  :src="'https://backend.telestatic.ru/upload/product_images/' + this.post.product.image"
                  max-height="400"
                  border="1"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
			</v-col>
		</v-row>
<!--    <v-dialog v-model="updateResultDialog">-->
<!--      {{updateResultText}}-->
<!--    </v-dialog>-->
    <loader :dialog.sync="updateResultDialog" :text="updateResultText" :action-finished="updateResultFinished"></loader>
	</v-container>
</template>

<script>
import _ from 'lodash'
import httpClient from '../../services/http.service'
import Editor from '../../components/posts/Editor'
import moment from 'moment'
import Links from '../../components/posts/Links'
import Loader from '@/components/common/Loader'
export default {
  name: 'Post',
  components:{
    Editor,
    Links,
    Loader,
  },
  data(){
    return{
      errors: [],
      updateResultDialog: false,
      updateResultFinished: false,
      updateResultText: '',
      // methodSelectDisabled: false,
      plannedAt: null,
      forceLinksUpdate: 0,
      textLength: 0,
      partnerId: null,
      method: null,
      post: null,
      links: null,
      sales: [],
      postLoaded: false,
      salesLoaded: false,
      postChannelsUsed: [], // тут список каналов, которые были выбраны изначально
      postChannels: [],
      hours: '',
      minutes: '',
      disableAlert: true,
      allowTimePick: true,
    }
  },
  computed:{
    channels(){
      return this.$store.state.channels
    },
    shops(){
      return this.$store.state.shops
    },
    partners(){
      return this.$store.state.partners
    },
    selectedTime(){
      return moment().hour(this.hours).minute(this.minutes).second(0).format('X')
    },
    showTime(){
      return moment().hour(this.hours).minute(this.minutes).format('HH:mm')
    },
    methodSelectDisabled(){
      return Boolean(this.plannedAt < Math.floor(Date.now() / 1000))
    }
  },
  watch: {
    hours(){
      this.$store.dispatch('needSaveResultPost', true)
    },
    disableAlert (v) {
      this.$emit('update:disableAlert', v)
    },
    link (v) {
      this.$emit('update:finalLink', v)
    },
    publishedChannels (v) {
      if (v === this.totalChannels) {
        this.$store.dispatch('needReloadScheduleEvents', true)
        this.progress = 100
        this.dialog = false
      }
    },
    textLength(v){
      if (v>1023){
        if (this.method !== 'sendPhoto'){
          if (this.post.status === 'Опубликован'){
            this.errors.push({error: 'tooLong', desc: 'Пост был опубликован в формате фото+подпись. Если пост уже был опубликован в телеге, то максимальная длина подписи - 1024 символа. Сделать больше уже нельзя. Нужно ужаться в 1024 символа или удалить этот пост и создать в CRM новый.'})
          }else if (this.post.status === 'Запланирован'){
            this.$toast('Тебе нужно успеть отредактировать пост, пока он не вышел, или укоротить текст до 1024 символов (иначе пизда)')
          }
        }
      }else{
        this.errors = this.errors.filter(x=>x.err !== 'tooLong')
      }
    },
    postLoaded(v){
      if (v){
        this.forceLinksUpdate++ //это запустит в компоненте Links обновление offer_id
      }
    }
  },
  mounted() {
    this.$store.dispatch('getChannels')
    this.$store.dispatch('getShops')
    this.$store.dispatch('getPartners')
    httpClient.get(`posts/${this.$route.params.id}`).then(({data})=>{
      this.post = data
      this.method = data.tg_method
      this.plannedAt = this.dateToTS(data.planned_at)
      if (this.plannedAt > 0){
        this.hours = new Date(this.plannedAt * 1000).getHours()
        this.minutes = new Date(this.plannedAt * 1000).getMinutes()
      }
      this.post.errors = []
      this.links = data.links
      _.each(this.links, (link, i) =>{
        if (i===0){
          this.partnerId = Number(link.partner_id)
        }
        this.links[i].title = link.title
        this.links[i].link = link.raw_link
        this.links[i].type = link.type
        this.links[i].partnerId = link.partner_id
      })
      // this.$refs.editor.setText(data.post.text)
      console.log('refs: ', this.$refs)
      this.postLoaded = true
      if (data.status === 'Запланирован') {
        this.$toast('Пост ещё не опубликован в телеге')
        this.allowTimePick = true
      }else{
        this.allowTimePick = false
      }
      httpClient.get('posts/sales?pid=' + this.$route.params.id).then(({data})=>{
        this.sales = data
        this.salesLoaded = true
        this.debUpdate()
      })
    })
    httpClient.get('posts/post-channels?pid='+this.$route.params.id).then(({data})=>{
      this.postChannels = data
      this.postChannelsUsed = data
    })
  },
  methods:{
    debUpdate:_.debounce(function(){
      this.forceLinksUpdate++ //надо подождать пару секунд (после загрузки компонента), и запустить получение offer_id (пиздец, сколько еботни из-за какой-то хуйни)
    }, 2000),
    dateToTS(date){
      console.log('parsing...', date)
      var datum = Date.parse(date)
      console.log('datum', datum)
      return Number(datum/1000)
    },
    deletePost(){
      this.updateResultDialog = true
      this.updateResultText = 'Удаляем...'
      httpClient.get('posts/delete-post?pid=' + this.$route.params.id).then(({data})=>{
        this.updateResultFinished = true
        if(data.success){
          this.updateResultText = 'Успешно удалили!'
        }else{
          this.updateResultText = `Не удалось удалить! <br> ${data.error_desc} `
        }
      })
    },
    publish2(){
      this.forceLinksUpdate++
      let offerId = 0
      if (this.partnerId === 1) {offerId = this.partnerId}
      else if (this.partnerId === 2) {offerId = this.post.shop.epn_id}
      else if (this.partnerId === 3) {offerId = this.post.shop.admitad_id}

      const info = {
        tg_method: this.method, //если пост уже вышел в телеге, то сервер отредактирует sendMessage на editMessageText (и фото)
        pid: this.post.pid,
        product_id: this.post.product_id,
        channels: this.postChannels,
        text: this.post.text,
        links: this.links,
        partner_id: this.partnerId,
        offer_id: offerId,
        shop_id: this.post.shop.id,
        disable_notification: true,
        post_type: 'single',
        url: this.post.link,
        aep_creative_id: this.post.aep_creative_id,
        erid: this.post.erid,
      }
      console.log('info for publish: ', info)
      this.updateResultDialog = true
      this.updateResultText = 'Сохраняем пост...'
      // httpClient.post('posts/publish?update=true&planned_at=' + this.selectedTime, info).then(({data})=>{
      httpClient.post('posts/update-post', info).then(({data})=>{
        this.publishInProgress = false
        this.updateResultFinished = true
        if (data.success){
          this.updateResultText = 'Успешно сохранили!'
        }else{
          this.updateResultText = 'Не удалось сохранить пост :(' + data.message
          this.$toast(data.error)

          // this.currentAction = 'Не удалось сохранить пост :(' + data.errors
        }
      })
    },
    publish(){
      //для начала - сравним кол-во каналов
      if (this.postChannels.length > this.postChannelsUsed.length){
        // добавили какой-то канал. бля.
      }
      else if (this.postChannels.length < this.postChannelsUsed.length){
        // убрали какой-то канал. тоже бля.
      }
      // httpClient.get('posts/pid-update?pid=' + this.$route.params.id + '&text=' + this.post.text + '&planned_at=' + this.selectedTime)
      // httpClient.post('posts/links-update?pid=' + this.$route.params.id, this.links)
    },
    getChDiff(was, now){

    },
    getPid(){
      return this.$route.params.id
    },
    hourDisabled(h){
      h++
      var currentD = new Date()
      var selected = new Date()
      selected.setHours(h, 0, 0)
      if(selected >= currentD) {
        return false
      }
      return true
    },
    minuteDisabled(m){
      var currentD = new Date()
      var selected = new Date()
      selected.setHours(this.hours, m, 0)
      if(selected >= currentD) {
        return false
      }
      return true
    },
    now(){
      this.minutes = new Date().getMinutes()
      this.hours = new Date().getHours()
    },
    getChannel(id){
      id = Number(id)
      console.log('searching for channel with id = ', id, this.channels)
      const channel = this.channels.find(x=>x.id===id)
      console.log(this.channels.find(x=>x.id===id))
      return channel.name
    },
    totalSales(){
      let sales = 0
      _.each(this.sales, sale=>{
        sales += Number(sale.primary) + Number(sale.secondary)
      })
      return sales
    }
  },
}
</script>

<style scoped>
	.v-btn-toggle {
		flex-direction: column;
	}
</style>
